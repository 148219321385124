import React from 'react';
import './reports.css'
export const Reports = () => {

    const tableData = [
        { id: 1, col1: 'Անուն ազգանուն', col2: 'Ամսաթիվ', col3: 'Հետազոտության տեսակ', col4: 'Արժեք', col5: 'Հաճախորդի տեսակ' },
    ];
    const tableDataFill = [
        { id: 2, col1: 'Պողոս Պողոսյան', col2: '15.07.2023', col3: 'հետազոտություն 1', col4: '10000 AMD', col5: 'Ֆիզ. անձ' },
        { id: 3, col1: 'Պետրոս Պետրոսյան', col2: '16.07.2023', col3: 'հետազոտություն 2', col4: '20000 AMD', col5: 'ֆիզ.անձ'},
        { id: 4, col1: 'Արթուր Գասպարյան / ՍՊԸ - 3', col2: '17.07.2023', col3: 'հետազոտություն 3', col4: '54000 AMD', col5: 'կորպորատիվ'},
        { id: 5, col1: 'Լեոնիդ Ռուբինյան / ՍՊԸ - 45', col2: '18.07.2023', col3: 'հետազոտություն 4', col4: '30000 AMD', col5: 'կորպորատիվ'},
        { id: 6, col1: 'Լևոն Խաչատրյան / ՍՊԸ - 12', col2: '20.07.2023', col3: 'հետազոտություն 5', col4: '34000 AMD', col5: 'կորպորատիվ'},
        { id: 7, col1: 'Ալբերտ Դավթյան', col2: '21.07.2023', col3: 'հետազոտություն 6', col4: '36000 AMD', col5: 'ֆիզ. անձ'},
        { id: 8, col1: 'Հարություն Հայրապետյան / ՍՊԸ - 32', col2: '22.07.2023', col3: 'հետազոտություն7', col4: '37000 AMD', col5: 'կորպորատիվ'},
    ];



  return (
        <table style = {{ marginLeft: '250px' }}>
            <thead>
            {tableData.map((row) => (
                <tr key={row.id}>
                    <th>{row.col1}</th>
                    <th>{row.col2}</th>
                    <th>{row.col3}</th>
                    <th>{row.col4}</th>
                    <th>{row.col5}</th>
                </tr>
            ))}
            </thead>

            <tbody>
            {tableDataFill.map((row) => (
                <tr key={row.id}>
                    <td>{row.col1}</td>
                    <td>{row.col2}</td>
                    <td>{row.col3}</td>
                    <td>{row.col4}</td>
                    <td>{row.col5}</td>
                </tr>
            ))}
            </tbody>
        </table>
  );
};

