import React from 'react';
import './overview.css'


const Overview = () => {
  return (
    <div className='home'>
    </div>
  );
};

export const OverviewOne = () => {
    const tableData = [
        { id: 1, col1: 'Անուն ազգանուն', col2: 'Պայմանագրի համար', col3: 'Ամսաթիվ', col4: 'Անձնագրի համար', col5: 'Հասցե' },
    ];
    const tableDataFill = [
        { id: 2, col1: 'Արթուր Գասպարյան / ՍՊԸ - 3', col2: '№ 31234', col3: '05.07.2023', col4: 'AP15329896', col5: 'ք Երևան' },
        { id: 3, col1: 'Լևոն Խաչատրյան / ՍՊԸ - 12', col2: '№ 87634', col3: '08.07.2023', col4: 'AP96354121', col5: 'ք Երևան'},
        { id: 4, col1: 'Լեոնիդ Ռուբինյան / ՍՊԸ - 45', col2: '№ 23334', col3: '10.07.2023', col4: 'AP87765534', col5: 'ք Երևան'},
        { id: 5, col1: 'Հարություն Հայրապետյան / ՍՊԸ - 32', col2: '№ 43634', col3: '11.07.2023', col4: 'AV11123234', col5: 'ք Երևան'},
    ];
    return(
        <table style={ {marginLeft: '250px'} }>
            <thead>
            {tableData.map((row) => (
                <tr key={row.id}>
                    <th>{row.col1}</th>
                    <th>{row.col2}</th>
                    <th>{row.col3}</th>
                    <th>{row.col4}</th>
                    <th>{row.col5}</th>
                </tr>
            ))}
            </thead>

            <tbody>
            {tableDataFill.map((row) => (
                <tr key={row.id}>
                    <td>{row.col1}</td>
                    <td>{row.col2}</td>
                    <td>{row.col3}</td>
                    <td>{row.col4}</td>
                    <td>{row.col5}</td>
                </tr>
            ))}
            </tbody>
        </table>

    );
};

export const OverviewTwo = () => {
    const tableData = [
        { id: 1, col1: 'Անուն ազգանուն', col2: 'Պայմանագրի համար', col3: 'Ամսաթիվ', col4: 'Անձնագրի համար', col5: 'Հասցե' },
    ];
    const tableDataFill = [
        { id: 2, col1: 'Պողոս Պողոսյան', col2: '№ 34653', col3: '14.07.2023', col4: 'AP49896435', col5: 'ք Երևան' },
        { id: 3, col1: 'Պետրոս Պետրոսյան', col2: '№ 35634', col3: '15.07.2023', col4: 'AP82746898', col5: 'ք Երևան'},
        { id: 4, col1: 'Նարեկ Ենգիբարյան', col2: '№ 38637', col3: '16.07.2023', col4: 'AV82719387', col5: 'ք Երևան'},
        { id: 5, col1: 'Ալբերտ Դավթյան', col2: '№ 38638', col3: '20.07.2023', col4: 'AP87768798', col5: 'ք Երևան'},
    ];
    return(
        <table style={{ marginLeft: '250px' }}>
            <thead>
            {tableData.map((row) => (
                <tr key={row.id}>
                    <th>{row.col1}</th>
                    <th>{row.col2}</th>
                    <th>{row.col3}</th>
                    <th>{row.col4}</th>
                    <th>{row.col5}</th>
                </tr>
            ))}
            </thead>

            <tbody>
            {tableDataFill.map((row) => (
                <tr key={row.id}>
                    <td>{row.col1}</td>
                    <td>{row.col2}</td>
                    <td>{row.col3}</td>
                    <td>{row.col4}</td>
                    <td>{row.col5}</td>
                </tr>
            ))}
            </tbody>
        </table>

    );
};
export const OverviewThree = () => {
    const tableData = [
        { id: 1, col1: 'Կոդ', col2: 'Ծառայության անվանում', col3: 'Արժեք'},
    ];
    const tableDataFill = [
        { id: 2, col1: '001', col2: 'Հայաստանի Հանրապետության քաղաքացիական ավիացիայի թռիչքների անվտանգության բժշկական ապահովման կազմակերպում', col3: '10000 AMD'},
        { id: 3, col1: '002', col2: 'Թռչող և կարգավարական աձնակազմի և ավիացիոն այլ մասնագետների բժշկական փորձաքննություն', col3: '13000 AMD'},
        { id: 4, col1: '003', col2: 'Թռչող անձնակազմի, կարգավարների և վարորդների նախաթռիչքային, նախահերթափոխային բժշկական հսկողություն', col3: '17000 AMD'},
        { id: 5, col1: '004', col2: 'Մասնագիտական հիվանդությունների վաղ հայտնաբերում և կանխարգելում', col3: '23000 AMD'},
        { id: 6, col1: '005', col2: 'Հիգիենիկ հակահամաճարակային միջոցառումներ, այդ թվում դեռատիզացիոն, դեզինֆեկցիոն և դեզինսեկցիոն' +
                ' աշխատանքներ օդանավակայանում գտնվող կազմակերպությունների տարածքում և օդանավերի սրահում', col3: '45000 AMD'},
        { id: 7, col1: '006', col2: 'Ջրի և սննդի մանրէաբանական հետազոտություն', col3: '20000 AMD'},
        { id: 8, col1: '007', col2: 'Անհետաձգելի առաջին բժշկական օգնություն օդանավակայանի աշխատակիցներին և ուղևորներին', col3: '30000 AMD'},
    ];
    return(
        <table className="third-table" style={{ marginLeft: '250px' }}>
            <thead>
            {tableData.map((row) => (
                <tr className="third-table-th" key={row.id}>
                    <th className="third-table-th1">{row.col1}</th>
                    <th className="third-table-th2">{row.col2}</th>
                    <th className="third-table-th3">{row.col3}</th>
                </tr>
            ))}
            </thead>
            <tbody>
            {tableDataFill.map((row) => (
                <tr className="third-table-tr" key={row.id}>
                    <td className="third-table-tr1">{row.col1}</td>
                    <td className="third-table-tr2">{row.col2}</td>
                    <td className="third-table-tr3">{row.col3}</td>
                </tr>
            ))}
            </tbody>
        </table>

    );
};


export default Overview;
