import React, {useState} from 'react';
import './Login.css'
import usernameImg from "../images/login-svgrepo-com.svg";
import pass from "../images/password-svgrepo-com.svg";

const Login = ({ handleLogin, error}) => {

        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');

        const handleSubmit = (e) => {
            e.preventDefault();
            handleLogin(username, password);
        };


    return (
        <div className="main">
            <div className="sub-main">
                <div>
                    <div className="login-form">
                        <h1>Մուտք</h1>
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                        <form onSubmit={handleSubmit} className="form-box" action="">
                            <div className="form-item">
                                <img src={usernameImg} alt="username" className="img-user"/>
                                <input type="text"
                                       value={username}
                                       onChange={(e) => setUsername(e.target.value)}
                                       placeholder="ծածկանուն" className="name"/>
                            </div>
                            <div className="form-item">
                                <img src={pass} alt="username" className="img-pass"/>
                                <input type="password"
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                       placeholder="ծածկագիր" className="name"/>
                            </div>
                            <div className="login-button">
                                <button type="submit" className="button-71">Մուտք գործել</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;